import { featureFlags } from '../utils/constants';
import useSplit from './useSplit';

const useShowNavigationBarTestA = () => {
  const { navigationBar } = featureFlags;
  const { isReady, treatment } = useSplit(navigationBar);

  return {
    isReady,
    treatment,
  };
};

export default useShowNavigationBarTestA;
