import { useContext, useEffect } from 'react';
import {
  measureAboutYou,
  measureCollectTransactions,
  measureContact,
  measureCookies,
  measureFaq,
  measureFeedbackSuccess,
  measureGetOffers,
  measureHomepage,
  measureInvoicesAddAccount,
  measureInvoicesOverview,
  measureNewToTheBank,
  measureNextSteps,
  measurePersonalOffer,
  measureRecurringModal,
  measureRedirection,
  measureRequestFailed,
  measureTransactionConsent,
  measureTransactionsAddAccount,
  measureTransactionsOverview,
  measureTransactionSuccess } from '../utils/analytics';
import { featureFlags } from '../utils/constants';
import { StoreContext } from '../utils/store';
import useSplit from './useSplit';

const measureMapper = {
  homepage: measureHomepage,
  aboutYou: measureAboutYou,
  invoicesAddAccount: measureInvoicesAddAccount,
  invoicesOverview: measureInvoicesOverview,
  transactionConsent: measureTransactionConsent,
  transactionsAddAccount: measureTransactionsAddAccount,
  redirection: measureRedirection,
  transactionSuccess: measureTransactionSuccess,
  collectTransactions: measureCollectTransactions,
  transactionsOverview: measureTransactionsOverview,
  getOffers: measureGetOffers,
  personalOffer: measurePersonalOffer,
  nextSteps: measureNextSteps,
  feedbackSuccess: measureFeedbackSuccess,
  newToTheBank: measureNewToTheBank,
  cookies: measureCookies,
  faq: measureFaq,
  contact: measureContact,
  recurringModal: measureRecurringModal,
  requestFailed: measureRequestFailed,
} as const;

type MeasureName = keyof typeof measureMapper;

type UsePageMeasureArgs = {
  name: MeasureName;
  value?: string;
};

function usePageMeasure({ name, value }: UsePageMeasureArgs) {
  const { navigationBar } = featureFlags;
  const { isReady, treatment } = useSplit(
    navigationBar,
  );

  const { state } = useContext(StoreContext);

  const { requester } = state;
  const { newToTheBank } = requester;

  useEffect(() => {
    if (!isReady) {
      return;
    }

    measureMapper[name]({ treatment, value, newToTheBank });
  }, [isReady, treatment]);
}

export default usePageMeasure;
