import type { AccountDetails } from '../services/invoice-finance-api';

export const IS_TESTER = 'tester';
export const invoiceType = {
  b2c: 'b2c',
  b2b: 'b2b',
};

export const BV_ABBREVIATION = 'b.v.';

export const pageMapper = {
  home: '/',
  compareProducts: '/verschil-factoring-factuur-financiering',
  shorttermLoan: '/kortlopende-lening',
  redirect: '/redirect',
  products: '/producten',
  faq: '/veelgestelde-vragen',
  aboutYou: '/jouw-aanvraag',
  contact: '/contact',
  cookies: '/cookies',
  aboutFundr: '/over-funder',
  invoices: '/jouw-aanvraag/facturen',
  transactions: '/jouw-aanvraag/transacties-uploaden',
  getTransactions: '/jouw-aanvraag/transacties-verzamelen',
  getOffers: '/jouw-aanvraag/get-offers',
  newToTheBank: '/jouw-aanvraag/new-to-the-bank',
  offer: '/jouw-aanvraag/persoonlijk-aanbod',
  offerSuccess: '/jouw-aanvraag/volgende-stappen',
  requestFailed: '/jouw-aanvraag/request-failed',
  contactRequestReceived: '/contact-request-received',
  feedbackSuccess: '/jouw-aanvraag/feedback-success',
  transactionSuccess: '/jouw-aanvraag/transaction-success',
  redirection: '/redirection',
} as const;

export type PageValue = typeof pageMapper[keyof typeof pageMapper];

export const restrictedPageOrder: Partial<Record<PageValue, number>> = {
  [pageMapper.offerSuccess]: -1,
  [pageMapper.feedbackSuccess]: -1,
  [pageMapper.aboutYou]: 1,
  [pageMapper.invoices]: 2,
  [pageMapper.transactions]: 3,
  [pageMapper.getOffers]: 4,
  [pageMapper.offer]: 5,
  [pageMapper.newToTheBank]: 6,
};

export const adornmentAlignment = {
  top: 'items-start',
  center: 'items-center',
  bottom: 'items-end',
};

export const storageKeys = {
  partnerId: 'partner_id',
  jwt: 'jwt',
  caseId: 'case_id',
  navigationHeight: 'navigation_height',
  utmCampaign: 'utm_campaign',
  correlationId: 'correlation_id',
  hash: 'hash',
  hasVisited: 'has_visited',
  progressBarWidth: 'bar_width',
  progressBarPosition: 'bar_position',
  userId: 'user_id',
};

export type BankCode =
  | 'RABOBANK'
  | 'ING'
  | 'ABN'
  | 'KNAB'
  | 'ASN'
  | 'SNS'
  | 'REGIOBANK'
  | 'TRIODOS'
  | 'BUNQ';

export const bankCodeToBankName: { [key in BankCode]: string } = {
  RABOBANK: 'Rabobank',
  ING: 'ING Bank',
  ABN: 'ABN Amro',
  KNAB: 'Knab',
  ASN: 'ASN Bank',
  SNS: 'SNS Bank',
  REGIOBANK: 'Regiobank',
  TRIODOS: 'Triodos',
  BUNQ: 'Bunq Bank',
};

export type ConnectionDetail = {
  connectionId: string;
  consentId?: string;
  needReconsent?: boolean;
  consentExpiresAt?: string;
  bankName: BankCode;
  accounts: AccountDetails[];
};

export const featureFlags = {
  customBanner: 'ff_banner_maintenance',
  startButtonText: 'ff_start_button_text',
  busyBanner: 'ff_pbl_busy_banner',
  auth: 'ff_auth',
  navigationBar: 'ff_pbl_new_navigation_bar',
};

export const invoiceViews = {
  addInvoice: 'add_invoice',
  preview: 'invoice_preview',
  overview: 'invoice_overview',
};

export const accountViews = {
  addTransaction: 'add_transaction',
  overview: 'transaction_overview',
};

export type NavigationDirection =
  | 'next'
  | 'prev';

export type BankMessageView = {
  navigateTo: (direction: NavigationDirection) => void;
  setShowModal: (value: boolean) => void;
};
